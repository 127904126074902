<script setup>
import Header from '~/layouts/headers/Header.vue'
// Lazy load the Footer component
const LazyFooter = defineAsyncComponent(() => import('~/layouts/footers/Footer.vue'))

useHead({
  title: 'Canopy',
  link: [
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'
    }
  ]
})

</script>
<template>
  <div>
    <Header />
    <CommonButtonsTapToCall />
    <div class=" bg-white">
      <slot />
    </div>
    <LazyFooter />
  </div>
</template>
