<template>
  <a :href="`tel:${phoneNumber}`" :class="`${isFixed ? 'a-cta-fixed' : ''} text-xl`">
    <div
      ref="phoneDiv"
      :class="`${isFixed ? 'fixed top-0 z-50 md:mt-[0px] md:pt-2' : 'md:mt-[10px]'} w-full bg-red-700 text-white flex flex-col md:flex-row md:gap-[20px] justify-center items-center py-2 md:pt-12`"
    >
      <div class="text-xl font-bold hidden lg:block">
        Call Now for a Free Estimate
      </div>
      <div class="text-xl font-bold lg:hidden">
        Tap to Call
      </div>
      <div class="flex items-center">
        <UIcon name="i-heroicons-device-phone-mobile-20-solid" />
        <span class="pl-1">{{ phoneNumber }}</span>
      </div>
    </div>
  </a>
</template>

<script setup lang="ts">
import { usePhoneNumber } from '@/composables/usePhoneNumber'

const phoneNumber = usePhoneNumber()
const isFixed = ref(false)
const phoneDiv = ref<HTMLElement | null>(null)
let initialTop = 0

const handleScroll = () => {
  if (phoneDiv.value) {
    const rect = phoneDiv.value.getBoundingClientRect()
    if (window.scrollY > initialTop) {
      if (!isFixed.value) {
        isFixed.value = rect.top <= 0
      }
    } else if (isFixed.value) {
      isFixed.value = false
    }
  }
}

onMounted(() => {
  if (phoneDiv.value) {
    initialTop = phoneDiv.value.offsetTop
  }
})

useEventListener(window, 'scroll', handleScroll)
</script>

<style>
a.a-cta-fixed + div {
  margin-top: 70px;
}
</style>
